// Here you can add other styles

// custom .chartjs-tooltip-body-item padding
@import "charts";

// custom tweaks for scrollbar styling (wip)
@import "scrollbar";

 // custom calendar today cell color
.calendar-cell.today {
  --cui-calendar-cell-today-color: var(--cui-info) !important;
}

// custom select week cursor pointer
.select-week .calendar-row.current {
  cursor: pointer;
}
.sidebar-dark {
  --cui-body-color: rgb(8 8 8 / 87%);
  --cui-body-bg: #e7f0f0;
  --cui-emphasis-color: #fff;
  --cui-secondary-color: rgba(255, 255, 255, 0.6);
  --cui-secondary-bg: #031433;
  --cui-tertiary-color: rgba(255, 255, 255, 0.38);
  --cui-tertiary-bg: #2a303d;
  --cui-border-color: #323a49;
}
.border-end {
   border-right: var(--cui-border-width) var(--cui-border-style) #e7f0f0 !important;
}
// .sidebar-nav {
//   --cui-sidebar-nav-padding-x: 0.5rem;
//   --cui-sidebar-nav-padding-y: 0.5rem;
//   --cui-sidebar-nav-title-padding-x: 1rem;
//   --cui-sidebar-nav-title-padding-y: 0.75rem;
//   --cui-sidebar-nav-title-margin-top: 1rem;
//   --cui-sidebar-nav-title-color: var(--cui-tertiary-color);
//   --cui-sidebar-nav-link-padding-x: 1rem;
//   --cui-sidebar-nav-link-padding-y: 0.75rem;
//   --cui-sidebar-nav-link-color: var(--cui-body-color);
//   --cui-sidebar-nav-link-bg: transparent;
//   --cui-sidebar-nav-link-border-color: transparent;
//   --cui-sidebar-nav-link-border-radius: var(--cui-border-radius);
//   --cui-sidebar-nav-link-border-width: 0;
//   --cui-sidebar-nav-link-active-color: var(--cui-emphasis-color);
//   --cui-sidebar-nav-link-active-bg: var(--cui-tertiary-bg);
//   --cui-sidebar-nav-link-disabled-color: var(--cui-tertiary-color);
//   --cui-sidebar-nav-link-hover-color: var(--cui-emphasis-color);
//   --cui-sidebar-nav-link-hover-bg: var(--cui-tertiary-bg);
//   --cui-sidebar-nav-link-icon-margin: 0.75rem;
//   // --cui-sidebar-nav-link-icon-color: rgb(29 27 27 / 38%);
// }
.sidebar-dark {
  --cui-body-color: rgb(8 8 8 / 87%);
  --cui-body-bg: #e7f0f0;
  --cui-emphasis-color: #fff;
  --cui-secondary-color: rgb(55 54 54 / 60%);
  --cui-secondary-bg: #031433;
  --cui-tertiary-color: #080a0c;
  --cui-tertiary-bg: #2a303d;
  --cui-border-color: #323a49;
}
.sidebar-nav {
  --cui-sidebar-nav-padding-x: 0.5rem;
  --cui-sidebar-nav-padding-y: 0.5rem;
  --cui-sidebar-nav-title-padding-x: 1rem;
  --cui-sidebar-nav-title-padding-y: 0.75rem;
  --cui-sidebar-nav-title-margin-top: 1rem;
  --cui-sidebar-nav-title-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-link-padding-x: 1rem;
  --cui-sidebar-nav-link-padding-y: 0.75rem;
  --cui-sidebar-nav-link-color: var(--cui-body-color);
  --cui-sidebar-nav-link-bg: transparent;
  --cui-sidebar-nav-link-border-color: transparent;
  --cui-sidebar-nav-link-border-radius: var(--cui-border-radius);
  --cui-sidebar-nav-link-border-width: 0;
  --cui-sidebar-nav-link-active-color: #fff;
  --cui-sidebar-nav-link-active-bg: #004745;;
  --cui-sidebar-nav-link-disabled-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-link-hover-color: #fff;
  --cui-sidebar-nav-link-hover-bg: #004745;;
  --cui-sidebar-nav-link-icon-margin: 0.75rem;
  --cui-sidebar-nav-link-icon-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-link-icon-width: 1.25rem;
  --cui-sidebar-nav-link-icon-height: 1.25rem;
  --cui-sidebar-nav-link-icon-font-size: 1.25rem;
  --cui-sidebar-nav-link-active-icon-color: #fff;
  --cui-sidebar-nav-link-disabled-icon-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-link-hover-icon-color: #fff;
  --cui-sidebar-nav-link-icon-bullet-size: 0.3125rem;
  --cui-sidebar-nav-link-icon-bullet-bg: transparent;
  --cui-sidebar-nav-link-icon-bullet-border-width: 1px;
  --cui-sidebar-nav-link-icon-bullet-border-radius: 50rem;
  --cui-sidebar-nav-link-icon-bullet-border-color: rgba(255, 255, 255, 0.87);
  --cui-sidebar-nav-link-active-icon-bullet-bg: transparent;
  --cui-sidebar-nav-link-active-icon-bullet-border-color: var(--cui-emphasis-color);
  --cui-sidebar-nav-link-disabled-icon-bullet-bg: transparent;
  --cui-sidebar-nav-link-disabled-icon-bullet-border-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-link-hover-icon-bullet-bg: transparent;
  --cui-sidebar-nav-link-hover-icon-bullet-border-color: var(--cui-body-color);
  --cui-sidebar-nav-group-bg: transparent;
  --cui-sidebar-nav-group-border-width: 0;
  --cui-sidebar-nav-group-border-radius: var(--cui-border-radius);
  --cui-sidebar-nav-group-border-color: transparent;
  --cui-sidebar-nav-group-items-padding-y: 0;
  --cui-sidebar-nav-group-items-padding-x: 0;
  --cui-sidebar-nav-group-indicator-color: var(--cui-tertiary-color);
  --cui-sidebar-nav-group-indicator-icon: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-nav-group-indicator-hover-color: var(--cui-emphasis-color);
  --cui-sidebar-nav-group-indicator-hover-icon: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-nav-group-toggle-show-color: var(--cui-body-color);
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--cui-sidebar-nav-padding-y) var(--cui-sidebar-nav-padding-x);
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
}
a.custom-btn,
button.custom-btn {
  border: 1px solid #073836 !important;
  background: #073836 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 0.5rem 1rem !important;
}
a.custom-btn:hover,
button.custom-btn:hover {
  background: #073836 !important;
  color: #fff !important;
}


.header > .container-fluid, .header > .container-sm, .header > .container-md, .header > .container-lg, .header > .container-xl, .header > .container-xxl, .sidebar-header {
  min-height: calc(3rem + 1px);
}
.header > .container-fluid + .container-fluid{
  min-height: 2.2rem;
}

// profile menu at top right corner
.ms-2 {
  margin-left: 0rem !important;
  margin-top: 2px !important;
  text-align: center;
}
.lnkLogout{
  cursor: pointer !important;
}
